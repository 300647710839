import "flowbite";
import Highway from "@dogstudio/highway";
import CustomTransition from "./transation";
import Menu from "./menu";
import { modifyLogo, loadScrollAnimation } from "./component";
import contactSales from "./contactus";

const menu = new Menu();

new Highway.Core({
  transitions: {
    default: CustomTransition,
  },
});

window.onbeforeunload = () => {
  window.scrollTo(0, 0);
};

// Import elemen modal jika diperlukan
// const modal = document.getElementById('myModal');

// Definisikan fungsi btnClose di sini
function btnClose() {
  const modal = document.getElementById('myModal'); // Sesuaikan dengan ID modal Anda
  modal.style.display = "none";
  document.body.classList.remove("overflow-y-hidden");
}

window.onload = () => {
  modifyLogo();
  loadScrollAnimation();
  contactSales();
  const links = document.querySelectorAll("nav a");
  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener("click", () => {
      menu.closeMenu();
    });
  }
  let counterM = 0;
  const mobileHideMenu = document.querySelector("#dropdownNavbarLinkMobile");
  mobileHideMenu.addEventListener("click", () => {
    if (counterM < 1) {
      counterM++;
      document.querySelector("#dropdownNavbarMobile").classList.remove('hidden');
    } else {
      counterM--;
      document.querySelector("#dropdownNavbarMobile").classList.add('hidden');
    }
  });

  // Tambahkan event listener untuk tombol penutup (X)
  const closeButton = document.querySelector(".btn-closing"); // Pastikan sesuai dengan selector tombol penutup (X)
  if (closeButton) {
    closeButton.addEventListener("click", btnClose);
  }
};
